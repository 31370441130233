import { Box, Icon } from '@chakra-ui/react';
import { SpinnerIcon } from '@chakra-ui/icons';
import { keyframes } from '@emotion/react';
import React from 'react';

const spin = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`;

const pulse = keyframes`
  0% { transform: scale(1); opacity: 0.8; }
  50% { transform: scale(1.2); opacity: 1; }
  100% { transform: scale(1); opacity: 0.8; }
`;

export function ProcessingSpinner() {
  return (
    <Box position="relative" h="100px" w="100px">
      <Box
        position="absolute"
        top="0"
        left="0"
        right="0"
        bottom="0"
        sx={{
          animation: `${spin} 2s linear infinite`
        }}
      >
        <Icon 
          as={SpinnerIcon}
          w={24}
          h={24}
          color="blue.500"
        />
      </Box>
      <Box
        position="absolute"
        top="50%"
        left="50%"
        transform="translate(-50%, -50%)"
        sx={{
          animation: `${pulse} 1.5s ease-in-out infinite`
        }}
      >
       
      </Box>
    </Box>
  );
}