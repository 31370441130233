import React, { useState, useEffect } from 'react';
import {
  Flex,
  FlexProps,
  IconButton,
  useColorModeValue,
  Text,
  Box,
  MenuButton,
  MenuList,
  HStack,
  MenuDivider,
  MenuItem,
  Menu,
  Avatar,
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  VStack,
  ModalBody,
  Input,
  ModalFooter,
  Button
} from '@chakra-ui/react';
import { useToast } from '@chakra-ui/react';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import { FiMenu, FiChevronDown, FiEdit } from 'react-icons/fi';

import { signOut } from 'firebase/auth';
import { auth } from '../../config.js';
import ChatGPTIcon from "../../Assets/chatgpt-bw.png";

interface MobileProps extends FlexProps {
  onOpen: () => void;
}

const MobileNav = ({ onOpen, ...rest }: MobileProps) => {
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState({});
  const [showCreateClassModal, setShowCreateClassModal] = useState<boolean>(false);
  const [showProfileModal, setShowProfileModal] = useState<boolean>(false);
  const [showChatBox, setShowChatBox] = useState<boolean>(false);
  const userType = sessionStorage.getItem("userType");
  

  useEffect(() => {
    if (userType === "admin") {
      const stored = sessionStorage.getItem("adminUserData");
      const user = JSON.parse(stored);
      setUserInfo(user);
    } 
  }, [userType]);


  const handleLogout = async () => {
    try {
      await signOut(auth);
      sessionStorage.clear();
      navigate('/')
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

 

  return (
    <>
    <Flex
      ml={{ base: 0, md: "16rem" }}
      px={{ base: 4, md: 4 }}
      height="20"
      alignItems="center"
      bg={useColorModeValue('white', 'gray.900')}
      borderBottomWidth="1px"
      borderBottomColor={useColorModeValue('gray.300', 'gray.700')}
      justifyContent={{ base: 'space-between', md: 'flex-end' }}
      position="fixed"
      top="0"
      left="0"
      right="0"
      {...rest}
      zIndex={103}
    >
      <IconButton
        display={{ base: 'flex', md: 'none' }}
        onClick={onOpen}
        variant="outline"
        aria-label="open menu"
        icon={<FiMenu />}
      />

      <Text
        display={{ base: 'flex', md: 'none' }}
        fontSize="2xl"
        fontFamily="monospace"
        fontWeight="bold"
      >
        Logo
      </Text>
      <HStack spacing={4}>
        

        <Flex alignItems={'center'}>
          <Menu>
            <MenuButton
              py={2}
              transition="all 0.3s"
              _focus={{ boxShadow: 'none' }}
            >
              <HStack>
                <Avatar
                  size={'sm'}
                  bg="purple.800"
                  textColor={'white'}
                  name={
                    _.capitalize((userInfo as any)?.name)
                  }
                />
                <VStack
                  display={{ base: 'none', md: 'flex' }}
                  alignItems="flex-start"
                  spacing="1px"
                  ml="2"
                >
                  <Text
                    fontSize="md"
                    fontWeight={'semibold'}
                    textColor={'purple.900'}
                    mb="0"
                  >
                    {userType === "admin"
                      ? userInfo?.name
                      : `${userInfo?.firstName} ${userInfo?.lastName}`
                    }

                  </Text>
                  {userType === "admin" ? (
                    <Text
                      fontSize="sm"
                      textColor="green.800"
                      fontWeight={'semibold'}
                    >
                      Admin
                    </Text>
                  ) : (
                    <></>
                  )}
                </VStack>
                <Box display={{ base: 'none', md: 'flex' }} mx="2">
                  <FiChevronDown size={18} />
                </Box>
              </HStack>
            </MenuButton>
            <MenuList
              bg={useColorModeValue('white', 'gray.900')}
              borderColor={useColorModeValue('gray.200', 'gray.700')}
            >
              <MenuItem onClick={handleLogout}>
                Logout
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </HStack>

    </Flex>
</>
  );
};

export default MobileNav;
