import { Box, SimpleGrid, Stat, StatLabel, StatNumber, Icon, Text, useColorModeValue, keyframes } from '@chakra-ui/react';
import { Users, GraduationCap, School, ListCheck } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import ADashboard from '../AdminDashboard/ADashboard.tsx';
import {  getAdminById, getClasses, getStudents, getTeachers } from '../../../Services/Admin/AdminService.tsx';

const grow = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
`;

function StatCard({ icon, label, value }: { icon: any; label: string; value: string }) {
  
  return (
    <Box
      p={6}
      bg="white"
      rounded="lg"
      shadow="sm"
      borderWidth="1px"
      _hover={{
        transform: 'translateY(-4px)',
        shadow: 'xl',
        transition: 'all 0.3s ease-in-out',
        animation: `${grow} 0.6s ease-in-out`,
      }}
    >
      <Stat>
        <Box display="flex" alignItems="center" mb={4}>
          <Icon as={icon} boxSize={8} mr={3} />
          <StatLabel fontSize="lg" fontWeight="medium">
            {label}
          </StatLabel>
        </Box>
        <StatNumber fontSize="4xl" fontWeight="bold">
          {value}
        </StatNumber>
      </Stat>
    </Box>
  );
}

function Dashboard() {
  const [teachers, setTeachers] = useState([]);
  const [students, setStudents] = useState([]);
  const isAdmin = sessionStorage.getItem('isAdmin');
  const [teacherCount, setTeacherCount] = useState(0);
  const [studentCount, setStudentCount] = useState(0);
  const [classes, setClasses] = useState([]);
  const [classCount, setClassCount] = useState(0);
  const [admin, setAdmin] = useState([]);
  const [requestCount, setRequestCount] = useState(0);
  const adminId = sessionStorage.getItem("CurrentLoggedInAId");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const adminResult = await getAdminById(adminId);
        const teachers = await getTeachers(isAdmin);
        const students = await getStudents(isAdmin);
        const classes = await getClasses(isAdmin);
        setAdmin(adminResult);
        setTeachers(teachers);
        setTeacherCount(teachers.length);
        setStudents(students);
        setStudentCount(students.length);
        setClasses(classes);
        setClassCount(classes.length);
        setRequestCount(adminResult?.requests.length || 0);
      } catch (error) {
        console.error('Error fetching dashboard data:', error);
      }
    };

    fetchData();
  }, [isAdmin]);

  return (
    <ADashboard>
      <Box mt={24} ml={6} mr={6}>
        <Text fontSize="2xl" fontWeight="bold" mb={8}>
          Dashboard Overview
        </Text>
        <SimpleGrid columns={{ base: 1, sm: 3 }} spacing={6}>
          <StatCard icon={Users} label="Total Teachers" value={teacherCount.toString()} />
          <StatCard icon={GraduationCap} label="Total Students" value={studentCount.toString()} />
          <StatCard icon={School} label="Active Classes" value={classCount.toString()} />
          <StatCard icon={ListCheck} label="Active Requests" value={requestCount.toString()} />
        </SimpleGrid>
      </Box>
    </ADashboard>
  );
}

export default Dashboard;
