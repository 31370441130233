import {
    Box,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Button,
    Text,
    Input,
    Spinner,
    HStack,
    IconButton,
  } from '@chakra-ui/react';
  import React, { useEffect, useState } from 'react';
  import ADashboard from '../AdminDashboard/ADashboard.tsx';
  import { getTeachers } from '../../../Services/Admin/AdminService.tsx';
  import { Trash2 } from 'lucide-react'; // Import delete icon from lucide-react
  import { FiTrash } from 'react-icons/fi';
  
  function Teachers() {
    const [teachers, setTeachers] = useState([]);
    const [filteredTeachers, setFilteredTeachers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const isAdmin = sessionStorage.getItem('isAdmin');
  
    useEffect(() => {
      const fetchData = async () => {
        setLoading(true);
        try {
          const teachers = await getTeachers(isAdmin);
          setTeachers(teachers);
          setFilteredTeachers(teachers); 
        } catch (error) {
          console.error('Error fetching dashboard data:', error);
        } finally {
          setLoading(false);
        }
      };
  
      fetchData();
    }, [isAdmin]);
  
   
    const getStudentDetails = (classes) => {
      let totalStudents = 0;
      const classDetails = [];
  
      classes.forEach((cls) => {
        const studentCount = cls.studentCount; 
        totalStudents += studentCount;
        classDetails.push({ className: cls.className, studentCount });
      });
  
      return { totalStudents, classDetails };
    };
  
    
    const handleSearch = (e) => {
      const query = e.target.value.toLowerCase();
      setSearchTerm(query);
  
      if (query === '') {
        setFilteredTeachers(teachers);
      } else {
        const filtered = teachers.filter(
          (teacher) =>
            (teacher.name && teacher.name.toLowerCase().includes(query)) ||
            (teacher.email && teacher.email.toLowerCase().includes(query))
        );
        setFilteredTeachers(filtered);
      }
    };
  
    return (
        <ADashboard>
          <Box mt={24} ml={6} mr={6}>
            <Text fontSize="2xl" fontWeight="bold" mb={8}>
              Manage Teachers
            </Text>
      
            <Box mb={4}>
              <Input
                width={400}
                placeholder="Search teachers..."
                value={searchTerm}
                onChange={handleSearch}
              />
            </Box>
      
            <Box
              bg="white"
              rounded="lg"
              shadow="sm"
              borderWidth="1px"
              maxH="550px"
              overflowY="auto"
            >
              {loading ? (
                <HStack justify="center" py={10}>
                  <Spinner size="lg" />
                </HStack>
              ) : filteredTeachers.length === 0 ? (
                <Box display="flex" justifyContent="center" alignItems="center" p={4}>
                  <Text fontSize="lg" fontWeight="medium" color="gray.500">No data found</Text>
                </Box>
              ) : (
                <Table variant="simple">
                  <Thead position="sticky" top="0" bg="white" zIndex="1">
                    <Tr>
                      <Th width="20%">Name</Th>
                      <Th width="25%">Email</Th>
                      <Th width="15%" textAlign="center">Total Classes</Th>
                      <Th width="15%" textAlign="center">Total Students</Th>
                      <Th width="15%" textAlign="center">Actions</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {filteredTeachers.map((teacher) => {
                      const { totalStudents, classDetails } = getStudentDetails(teacher.classes);
      
                      return (
                        <Tr key={teacher.id}>
                          <Td width="20%" fontWeight="medium">{teacher.name || "N/A - No account created"}</Td>
                          <Td width="25%">{teacher.email}</Td>
      
                          {/* Total Classes with hover effect */}
                          <Td width="15%" textAlign="center">
                            <Box
                              position="relative"
                              _hover={{
                                '& .classInfo': {
                                  opacity: 1,
                                  visibility: 'visible',
                                },
                              }}
                            >
                              {teacher.classes.length}
                              <Box
                                className="classInfo"
                                position="absolute"
                                top="100%"
                                left="50%"
                                transform="translateX(-50%)"
                                bg="white"
                                p={6}
                                borderRadius="md"
                                boxShadow="lg"
                                width="max-content"
                                zIndex={10}
                                minWidth="200px"
                                sx={{
                                  textAlign: 'left', 
                                }}
                                opacity={0}  
                                visibility="hidden"  
                                transition="opacity 1.1s ease, visibility 1.1s ease"
                              >
                                <Text fontWeight="bold" mb={3}>
                                  Classes Details:
                                </Text>
                                <ul style={{ paddingLeft: '1.5rem', listStyleType: 'disc' }}>
                                  {classDetails.map((cls, index) => (
                                    <li key={index} style={{ marginBottom: '0.5rem' }}>
                                      <Text mb={2} fontWeight="medium">
                                        {cls.className}
                                      </Text>
                                      <ul style={{ paddingLeft: '1.5rem' }}>
                                        <li>{cls.studentCount} students</li>
                                      </ul>
                                    </li>
                                  ))}
                                </ul>
                              </Box>
                            </Box>
                          </Td>
      
                          <Td width="15%" textAlign="center">
                            <Box
                              position="relative"
                              _hover={{
                                '& .studentInfo': {
                                  opacity: 1,
                                  visibility: 'visible',
                                },
                              }}
                            >
                              {totalStudents}
                              <Box
                                className="studentInfo"
                                position="absolute"
                                top="100%"
                                left="50%"
                                transform="translateX(-50%)"
                                bg="white"
                                p={6}
                                borderRadius="md"
                                boxShadow="md"
                                width="max-content"
                                zIndex={10}
                                minWidth="200px"
                                sx={{
                                  textAlign: 'left', 
                                }}
                                opacity={0}  
                                visibility="hidden"  
                                transition="opacity 1.1s ease, visibility 1.1s ease"
                              >
                                <Text fontWeight="bold" mb={2}>
                                  Classes Details:
                                </Text>
                                <ul style={{ paddingLeft: '1.5rem', listStyleType: 'disc' }}>
                                  {classDetails.map((cls, index) => (
                                    <li key={index} style={{ marginBottom: '0.5rem' }}>
                                      <Text fontWeight="medium">{cls.className}:</Text>
                                      <ul style={{ paddingLeft: '1.5rem' }}>
                                        <li>{cls.studentCount} students</li>
                                      </ul>
                                    </li>
                                  ))}
                                </ul>
                              </Box>
                            </Box>
                          </Td>
      
                          <Td width="15%" textAlign="center">
                            <IconButton
                              icon={<FiTrash />}
                              aria-label="Delete teacher"
                              color={'purple.900'}
                              size="lg"
                              bg={'white'}
                              onClick={() => alert('Delete functionality to be implemented')}
                            />
                          </Td>
                        </Tr>
                      );
                    })}
                  </Tbody>
                </Table>
              )}
            </Box>
          </Box>
        </ADashboard>
      );      
  }
  
  export default Teachers;
  