import React, { useState, useEffect } from 'react';
import { getClassesByUserId, uploadAssignment, fetchAssignmentsForTeacher } from '../../../Services/Teacher/teacherService.tsx';
import {
  Box,
  Button,
  Checkbox,
  Stack,
  Input,
  Text,
  HStack,
  useToast,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  VStack,
  Flex,
  Spinner,
  Heading,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  CheckboxGroup,
} from '@chakra-ui/react';
import { FaChevronDown, FaEye, FaTimes } from 'react-icons/fa';
import { CloseIcon } from '@chakra-ui/icons';
import { GoogleAuthProvider, getAuth, signInWithPopup } from "firebase/auth";
import { app } from "../../../config.js";
import { useNavigate } from 'react-router-dom';

const LearnQ = () => {
  const navigate = useNavigate();
  const [classes, setClasses] = useState([]);
  const [selectedClasses, setSelectedClasses] = useState([]);
  const [selectedClassesIds, setSelectedClassesIds] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [isOpen, setIsOpen] = useState(false); // State for modal visibility
  const toast = useToast();
  const userId = sessionStorage.getItem('CurrentLoggedInTId').trim();
  const [assignments, setAssignments] = useState([]);

  useEffect(() => {
    const fetchClasses = async () => {
      try {
        const classesData = await getClassesByUserId(userId, 'teacher');
        if(!classesData.message)
        setClasses(classesData);
        else
        setClasses([]);
      } catch (error) {
        toast({
          title: 'Error fetching classes',
          description: 'There was an error fetching your classes. Please try again later.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    };

    fetchClasses();
  }, [userId, toast]);
  
  useEffect(() => {
  const fetchAssignments = async () => {
    try {
      const assignmentsData = await fetchAssignmentsForTeacher(userId);
      console.log(assignmentsData)
      setAssignments(assignmentsData || []);
    } catch (error) {
      toast({
        title: 'Error fetching assignments',
        description: 'There was an error fetching your assignments. Please try again later.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  fetchAssignments();
}, [userId, toast]);


  const handleClassSelect = (selected) => {
    const selectedIds = selected.map((classname) => {
      const cls = classes.find(c => c.classname === classname);
      return cls ? cls._id : null; 
    }).filter(Boolean); 
  
    setSelectedClasses(selected);
    setSelectedClassesIds(selectedIds);
    setIsAllSelected(selected.length === classes.length);
  };

  const handleSelectAll = () => {
    if (isAllSelected) {
      setSelectedClasses([]);
      setSelectedClassesIds([]);
    } else {
      setSelectedClasses(classes.map((cls) => cls.classname));
      setSelectedClassesIds(classes.map((cls) => cls._id));
    }
    setIsAllSelected(!isAllSelected);
  };

  const handleFileSelect = (event) => {
    setSelectedFile(event.target.files[0]);
    setUploadSuccess(false);
  };

  const handleFileUpload = async () => {
    if (!selectedFile || selectedClasses.length === 0) {
      toast({
        title: 'Select classes and a file to upload',
        status: 'warning',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    setUploading(true);
    let token;
    const userType = sessionStorage.getItem("userType");
    
    if (userType === "student") {
      token = sessionStorage.getItem("studentGoogleAccessToken");
    } else if (userType === "teacher") {
      token = sessionStorage.getItem("googleAccessToken");
    }

    const expirationTime = new Date(sessionStorage.getItem("TokenExpirationTime"));
    const currentTime = new Date();
    const timeLeft = expirationTime - currentTime;
    if (timeLeft < 5 * 60 * 1000) {
      const auth = getAuth(app);
      const provider = new GoogleAuthProvider();
      provider.setCustomParameters({ prompt: "select_account" });
      provider.addScope("https://www.googleapis.com/auth/drive");
      provider.addScope("https://www.googleapis.com/auth/drive.file");
      provider.addScope("https://www.googleapis.com/auth/documents");
      const result = await signInWithPopup(auth, provider);
      const loggedInUser = result.user;
      const credential = GoogleAuthProvider.credentialFromResult(result);
      token = credential.accessToken;
      const accessToken = await loggedInUser.getIdTokenResult(true);
      if(userType == "student"){
      sessionStorage.setItem("studentGoogleAccessToken", token);
      sessionStorage.setItem("studentTokenExpirationTime", accessToken.expirationTime);
      }
      else if(userType == "teacher"){
        sessionStorage.setItem("googleAccessToken", token);
        sessionStorage.setItem("TokenExpirationTime", accessToken.expirationTime);
      }
    }
    
    try {
      const formData = new FormData();
      formData.append('classIds', selectedClassesIds.join(','));
      formData.append('userId', userId);
      formData.append('token', token);
      if (selectedFile) formData.append('file', selectedFile);
      const response = await uploadAssignment(formData);
      const newAssignment = response.assignment; 
      setAssignments((prevAssignments) => [...prevAssignments, newAssignment]);
      setUploading(false);
      setUploadSuccess(true);
      setIsOpen(false); 
      setSelectedClasses([]);
      setSelectedClassesIds([]);
      setSelectedFile(null);
      toast({
        title: 'Assignment uploaded successfully!',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      setUploading(false);
      toast({
        title: 'Error uploading file',
        description: 'There was an error uploading your assignment. Please try again.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Box>
      <Flex justify="right" mb={6}>
        <Button onClick={() => setIsOpen(true)} bg={'purple.900'} _hover={{bg:"purple.900"}} color={'white'}>Upload Assignment</Button>
      </Flex>

      <Box p={6} mx="auto" mt={4} borderWidth="1px" borderRadius="lg" boxShadow="lg" maxHeight="600px" overflowY="auto">
      <Heading fontSize="lg" mb={4}>Uploaded Files</Heading>
      <Table variant="simple" size="md">
        <Thead>
          <Tr>
            <Th>File Name</Th>
            <Th>Classes Shared</Th>
            <Th>Actions</Th>
          </Tr>
        </Thead>
        <Tbody>
          {assignments.length > 0 ? (
            assignments.map((file) => (
              <Tr key={file._id}> 
                <Td>{file.filename}</Td> 
                <Td>
                  {file.classFiles.map(cf => (
                    <span 
                      key={cf.classId._id} 
                      style={{ cursor: 'pointer', color: 'purple.900' }} 
                      onClick={() => {
                        sessionStorage.setItem('activeNav', null); 
                        navigate(`/teacher-portal/class/${cf.classId._id}`); 
                      }} 
                    >
                      {cf.classId.classname}
                    </span>
                  )).reduce((prev, curr) => [prev, ', ', curr])} 
                </Td>
                <Td>
                  <IconButton
                    aria-label="View file"
                    icon={<FaEye />}
                    variant={'ghost'}
                    onClick={() => window.open(file.classFiles[0].link, '_blank')} // Open link in a new tab
                  />
                </Td>
              </Tr>
            ))
          ) : (
            <Tr>
              <Td colSpan="3" textAlign="center">No uploaded files yet.</Td>
            </Tr>
          )}
        </Tbody>
      </Table>
    </Box>
{/* Modal for Uploading Assignment */}
<Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Upload Assignment</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <HStack spacing={4} mb={4}>
              <Text>Select classes:</Text>
              <Menu closeOnSelect={false}>
                <MenuButton as={Button} rightIcon={<FaChevronDown />} size="sm">
                  Classes
                </MenuButton>
                <MenuList maxH="300px" overflowY="auto">
                  <VStack align="start" px={4} py={2}>
                    <Checkbox isChecked={isAllSelected} onChange={handleSelectAll}>
                      Select All
                    </Checkbox>
                    <CheckboxGroup value={selectedClasses} onChange={handleClassSelect}>
                      {classes.length > 0 ? (
                        classes.map(cls => (
                          <Checkbox key={cls._id} value={cls.classname}>
                            {cls.classname}
                          </Checkbox>
                        ))
                      ) : (
                        <Text>No classes available.</Text>
                      )}
                    </CheckboxGroup>
                  </VStack>
                </MenuList>
                
              </Menu>
            </HStack>

            {selectedClasses.length > 0 && (
            <Box
              p={4}
              mt={4}
              mb={4}
              borderWidth="1px"
              borderRadius="md"
              bg="gray.50"
              position="relative"  
            >
              <IconButton
                icon={<CloseIcon />}
                aria-label="Close"
                size="sm"
                position="absolute"
                top={2}
                right={2}
                onClick={() => {
                  setSelectedClasses([]);         
                  setSelectedClassesIds([]);      
                }}
              />
              <Heading size="sm" mb={2}>Selected Classes:</Heading>
              {selectedClasses
                .map((classe) => (
                  <Text key={classe}>{classe}</Text>
                ))}
            </Box>
          )}

            {/* File Upload Section */}
            <Box 
              border="1px" 
              borderColor="gray.300" 
              p={4} 
              textAlign="center" 
              cursor="pointer"
              borderRadius="md"
              _hover={{ borderColor: 'gray.400' }}
              mb={4}
            >
              <Input
                type="file"
                onChange={handleFileSelect}
                accept=".doc,.docx,.pdf"
                display="none" // Hide the default file input
                id="file-input"
              />
              <label htmlFor="file-input" style={{ cursor: 'pointer' }}>
                {selectedFile ? (
                  <Text>{selectedFile.name}</Text>
                ) : (
                  <Text>Drag 'n' drop a file here, or click to select one</Text>
                )}
              </label>
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button bg={'purple.900'} _hover={{ bg: "purple.800" }} color={'white'} mr={3} onClick={handleFileUpload} isLoading={uploading}>
              Upload
            </Button>
            <Button onClick={() => setIsOpen(false)}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

    </Box>
  );
};

export default LearnQ;
