import {
  Box,
  Container,
  Heading,
  Text,
  VStack,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import { IconAnimation } from "./IconAnimation.tsx";
import { ActionButtons } from "./ActionButton.tsx";

const MotionBox = motion(Box);
const MotionHeading = motion(Heading);
const MotionText = motion(Text);

function AccessDenied() {
  return (
    <Box
      minH="100vh"
      bgGradient="linear(to-br, purple.50, white)"
      display="flex"
      alignItems="center"
      py={8}
      px={4}
    >
      <Container maxW="md">
        <MotionBox
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          bg="white"
          borderRadius="xl"
          p={8}
          boxShadow="2xl"
          border="1px"
          borderColor="purple.100"
        >
          <VStack spacing={6}>
            <IconAnimation />

            <MotionHeading
              as="h1"
              size="xl"
              textAlign="center"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.3 }}
              bgGradient="linear(to-r, purple.900, purple.600)"
              bgClip="text"
            >
              Access Denied
            </MotionHeading>

            <MotionText
              color="gray.600"
              textAlign="center"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.4 }}
            >
              You don't have access to this page
            </MotionText>

            <ActionButtons />
          </VStack>
        </MotionBox>
      </Container>
    </Box>
  );
}

export default AccessDenied;