import { Box } from "@chakra-ui/react";
import { motion } from "framer-motion";
import { ShieldX, Lock } from "lucide-react";
import React from "react";

const MotionBox = motion(Box);

export function IconAnimation() {
  return (
    <MotionBox
      initial={{ scale: 0 }}
      animate={{ scale: 1 }}
      transition={{ delay: 0.2, type: "spring", stiffness: 200 }}
      position="relative"
      w="80px"
      h="80px"
      mx="auto"
      mb={6}
    >
      <MotionBox
        position="absolute"
        inset={0}
        animate={{ 
          rotate: [0, -10, 10, -10, 0],
        }}
        transition={{ 
          duration: 2,
          repeat: Infinity,
          repeatType: "reverse",
        }}
      >
        <ShieldX size={80} color="#805AD5" />
      </MotionBox>
      <MotionBox
        position="absolute"
        bottom="-8px"
        right="-8px"
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ delay: 0.5 }}
      >
        <Lock size={32} color="#44337A" />
      </MotionBox>
    </MotionBox>
  );
}