import React, { useState } from "react";
import { Box, Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import LearnQ from "./LearnQ.jsx";
import TDashboard from "../Dashboard/Dashboard.tsx";
import LearnQHome from "./LearnQHome.jsx";
import QuestionTable from "./QuestionHistory.jsx";

const Message = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [fileText, setFileText] = useState(""); 
  const [classId, setClassId] = useState("");
  const [currentTId, setCurrentTId] = useState("");

  return (
    <TDashboard>
      <Box height="90%" p={4} textColor={'purple.900'} m={16}>
        <Tabs index={selectedTab} onChange={(index) => setSelectedTab(index)}>
          <TabList mb="1em">
            <Tab>Assignment</Tab>
            <Tab>LearnQ</Tab>
            <Tab>Questions History</Tab>
          </TabList>
         
          <TabPanels>
            <TabPanel>
              <LearnQHome setFileText={setFileText} setCurrentTId={setCurrentTId} setSelectedTab={setSelectedTab} setClassId={setClassId}/> 
            </TabPanel>
            <TabPanel>
              <LearnQ fileText={fileText} currentTId={currentTId} setFileText={setFileText} classId={classId} setClassId={setClassId}/> 
            </TabPanel>
            <TabPanel>
              <QuestionTable /> 
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </TDashboard>
  );
};

export default Message;
