import { Box, Heading, Text, Link, VStack } from '@chakra-ui/react';
import { ProcessingSpinner } from './ProcessingSpinner.tsx';
import React from 'react';

export function ProcessingCard() {
  return (
    <Box
      bg="white"
      p={12}
      borderRadius="2xl"
      boxShadow="2xl"
      w="full"
      maxW="600px"
      position="relative"
      overflow="hidden"
      transition="transform 0.2s ease-in-out"
      _before={{
        content: '""',
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        height: "6px",
        bgGradient: "linear(to-r, blue.400, purple.500)"
      }}
      _hover={{
        transform: "translateY(-2px)"
      }}
    >
      <VStack spacing={8}>
        <ProcessingSpinner />
        
        <Heading 
          size="lg"
          color="gray.800"
          letterSpacing="tight"
          textAlign="center"
        >
          Account Approval in Progress
        </Heading>

        <Text 
          fontSize="lg" 
          color="gray.600" 
          lineHeight="tall"
          textAlign="center"
        >
          Your account is currently under review by our team. 
          We're working diligently to process your application.
        </Text>

        <Text fontSize="md" color="gray.500" textAlign="center">
          For any inquiries, please contact{' '}
          <Link
            href="mailto:skammu@albany.edu"
            color="blue.500"
            fontWeight="medium"
            _hover={{
              color: 'blue.600',
              textDecoration: 'none',
              borderBottom: '2px solid',
              borderColor: 'blue.600'
            }}
          >
            skammu@albany.edu
          </Link>
        </Text>
      </VStack>
    </Box>
  );
}