import { VStack, Button, Link } from "@chakra-ui/react";
import { motion } from "framer-motion";
import React from "react";

const MotionButton = motion(Button);
const MotionVStack = motion(VStack);

export function ActionButtons() {
  return (
    <MotionVStack
      spacing={6}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ delay: 0.5 }}
    >
      <MotionButton
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        size="lg"
        bgGradient="linear(to-r, purple.500, purple.700)"
        color="white"
        _hover={{
          bgGradient: "linear(to-r, purple.600, purple.800)",
          shadow: "lg",
        }}
        onClick={() => window.history.back()}
      >
        Go Back
      </MotionButton>

      <Link
        href="/"
        fontSize="sm"
        color="purple.900"
        fontWeight="medium"
        _hover={{ color: "purple.700", textDecoration: "none" }}
      >
        Return to Homepage
      </Link>
    </MotionVStack>
  );
}