import { Box, VStack, Text, Icon, Link as ChakraLink } from '@chakra-ui/react';
import { Link, useLocation } from 'react-router-dom';
import { LayoutDashboard, Users, GraduationCap, School, UserCheck } from 'lucide-react';
import React from 'react';

const NavItem = ({ icon, children, to }: { icon: any; children: string; to: string }) => {
  const location = useLocation();
  const isActive = location.pathname === to;

  return (
    <ChakraLink
      as={Link}
      to={to}
      w="full"
      _hover={{ textDecoration: 'none' }}
    >
      <Box
        display="flex"
        alignItems="center"
        px={4}
        py={3}
        borderRadius="md"
        bg={isActive ? 'purple.100' : 'transparent'}
        color={isActive ? 'purple.900' : 'gray.700'}
        _hover={{ bg: isActive ? 'purple.100' : 'gray.100' }}
      >
        <Icon as={icon} mr={3} />
        <Text fontWeight="medium">{children}</Text>
      </Box>
    </ChakraLink>
  );
};

function Sidebar() {
  return (
    <Box
    w={{ base: "full", md: "16rem" }}
      bg="white"
      borderRight="1px"
      borderColor="gray.200"
      pos="fixed"
      h="114%"
      overflowY="auto"
      css={{
            '&::-webkit-scrollbar': {
                display: 'none',
            },
            '-ms-overflow-style': 'none',
            'scrollbar-width': 'none',
        }}
    >
      <Text
        fontSize="2xl"
        fontWeight="bold"
        color="purple.900"
        mb={8}
        px={4}
        mt={8}
        ml={3}
      >
        Generative Ideas
      </Text>
      <VStack ml={5} mr={5} spacing={2} align="stretch">
        <NavItem icon={LayoutDashboard} to="/admin-portal/home">Dashboard</NavItem>
        <NavItem icon={UserCheck} to="/admin-portal/teacherrequests">Teacher Requests</NavItem>
        <NavItem icon={Users} to="/admin-portal/teachers">Teachers</NavItem>
        <NavItem icon={GraduationCap} to="/admin-portal/students">Students</NavItem>
        <NavItem icon={School} to="/admin-portal/classes">Classes</NavItem>
      </VStack>
    </Box>
  );
}

export default Sidebar;