import React, { useState, useEffect } from 'react';
import { fetchAssignmentsForStudent } from '../../../Services/Student/studentService.tsx';
import { getClassesByUserId } from '../../../Services/Teacher/teacherService.tsx';
import { MdNotStarted } from "react-icons/md";

import {
  Box,
  useToast,
  IconButton,
  Heading,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Tooltip,
  Spinner,
  Flex,
} from '@chakra-ui/react';
import { FaEye  } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import * as mammoth from 'mammoth';
import * as pdfjsLib from 'pdfjs-dist';
import { GoogleAuthProvider, getAuth, signInWithPopup } from "firebase/auth";
import { app } from "../../../config.js";
import axios from 'axios';
pdfjsLib.GlobalWorkerOptions.workerSrc = '/pdf.worker.min.mjs';
  const MAX_FILE_SIZE_MB = 5;
  
  

const LearnQHome = ({ setFileText, setCurrentTId, setSelectedTab, setClassId }) => {
  const navigate = useNavigate();
  const toast = useToast();
  const userId = sessionStorage.getItem('CurrentLoggedInSId').trim();
  const [assignments, setAssignments] = useState([]);
  const [loading, setLoading] = useState(false); 
  
  useEffect(() => {
  const fetchAssignments = async () => {
    try {
      const assignmentsData = await fetchAssignmentsForStudent(userId);
      setAssignments(assignmentsData || []);
    } catch (error) {
      toast({
        title: 'Error fetching assignments',
        description: 'There was an error fetching your assignments. Please try again later.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  fetchAssignments();
}, [userId, toast]);

const extractFileData = async (file) => {
  
    if (file.size > MAX_FILE_SIZE_MB * 1024 * 1024) {
      alert(`File size exceeds the ${MAX_FILE_SIZE_MB}MB limit.`);
      return null;
    }
  
    const fileType = file.type;
  
    if (fileType.includes('pdf')) {
      
      const pdfData = await file.arrayBuffer();
      const pdf = await pdfjsLib.getDocument({ data: pdfData }).promise;
      let text = '';
      for (let i = 1; i <= pdf.numPages; i++) {
        const page = await pdf.getPage(i);
        const pageText = await page.getTextContent();
        text += pageText.items.map(item => item.str).join(' ');
      }
      return text;
    } else if (fileType.includes('word') || fileType.includes('vnd.openxmlformats-officedocument.wordprocessingml.document')) {
      
      const arrayBuffer = await file.arrayBuffer();
      const result = await mammoth.extractRawText({ arrayBuffer });
      return result.value; 
    } else if (fileType.includes('text/plain')) {
      
      const textData = await file.text();
      return textData;
    } else {
      alert('Unsupported file type.');
      return null;
    }
  };

  const downloadFileWithAccessToken = async (accessToken, fileId) => {
    const response = await axios.get(`https://www.googleapis.com/drive/v3/files/${fileId}?alt=media`, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
            responseType: 'blob', 
          });

          const contentType = response.headers['content-type'];
          const blob = response.data;
          
  
    if (response) {
      return blob; 
    } else {
      console.error('Error fetching file content:', response.statusText);
    }
  };
  

  const extractFileId = (link) => {
    const regex = /\/d\/(.*?)\//;
    const matches = link.match(regex);
    return matches ? matches[1] : null;
  };
  

const handleOpenQuizClick = async(file) => {
    setLoading(true);
    setCurrentTId(file.uploadedBy._id);
    setClassId(file.classFiles[0].classId._id)
    const fileId = extractFileId(file.classFiles[0].link);
    let token;
    if(fileId){
    const userType = sessionStorage.getItem("userType");
    if (userType === "student") {
      token = sessionStorage.getItem("studentGoogleAccessToken");
    } else if (userType === "teacher") {
      token = sessionStorage.getItem("googleAccessToken");
    }

    const expirationTime = new Date(sessionStorage.getItem("studentTokenExpirationTime"));
    const currentTime = new Date();
    const timeLeft = expirationTime - currentTime;
    if (timeLeft < 5 * 60 * 1000) {
      const auth = getAuth(app);
      const provider = new GoogleAuthProvider();
      provider.setCustomParameters({ prompt: "select_account" });
      provider.addScope("https://www.googleapis.com/auth/drive");
      provider.addScope("https://www.googleapis.com/auth/drive.file");
      provider.addScope("https://www.googleapis.com/auth/documents");
      const result = await signInWithPopup(auth, provider);
      const loggedInUser = result.user;
      const credential = GoogleAuthProvider.credentialFromResult(result);
      token = credential.accessToken;
      const accessToken = await loggedInUser.getIdTokenResult(true);
      if(userType == "student"){
      sessionStorage.setItem("studentGoogleAccessToken", token);
      sessionStorage.setItem("studentTokenExpirationTime", accessToken.expirationTime);
      }
      else if(userType == "teacher"){
        sessionStorage.setItem("googleAccessToken", token);
        sessionStorage.setItem("TokenExpirationTime", accessToken.expirationTime);
      }
    }
    const fileContent = await downloadFileWithAccessToken(token, fileId);
    const fileText = await extractFileData(fileContent);
        setFileText(fileText); 
      setSelectedTab(1);
    }
    setLoading(false);
    
  };


  return (
    <Box>

      <Box p={6} mx="auto" mt={4} borderWidth="1px" borderRadius="lg" boxShadow="lg">
      <Heading fontSize="lg" mb={4}>Uploaded Files</Heading>
      {loading ? (
    <Flex justify="center" align="center" height="100%">
        <Spinner />
   </Flex>
) : (
      <Table variant="simple" size="md">
        <Thead>
          <Tr>
            <Th>File Name</Th>
            <Th>Classes Shared</Th>
            <Th>Uploaded By</Th>
            <Th>Actions</Th>
          </Tr>
        </Thead>
        <Tbody>
          {assignments.length > 0 ? (
            assignments.map((file) => (
              <Tr key={file._id}> 
                <Td>{file.filename}</Td> 
                <Td>
                  {file.classFiles.map(cf => (
                    <span 
                      key={cf.classId._id} 
                      style={{ cursor: 'pointer', color: 'purple.900' }} 
                      onClick={() => {
                        sessionStorage.setItem('activeNav', null); 
                        navigate(`/student-portal/class/${cf.classId._id}`); 
                      }} 
                    >
                      {cf.classId.classname}
                    </span>
                  )).reduce((prev, curr) => [prev, ', ', curr])} 
                </Td>
                <Td>{file.uploadedBy.name}</Td>
                <Td>
                <Tooltip label="View file" aria-label="View file tooltip">
                    <IconButton
                    aria-label="View file"
                    icon={<FaEye />}
                    variant={'ghost'}
                    onClick={() => window.open(file.classFiles[0].link, '_blank')} 
                    />
                </Tooltip>

                <Tooltip label="Open Quiz" aria-label="Open Quiz tooltip">
                    <IconButton
                    aria-label="Open Quiz"
                    icon={<MdNotStarted />}
                    variant={'ghost'}
                    onClick={() => handleOpenQuizClick(file)}
                    />
                </Tooltip>
                </Td>
                
                 
                
              </Tr>
            ))
          ) : (
            <Tr>
              <Td colSpan="3" textAlign="center">No uploaded files yet.</Td>
            </Tr>
          )}
        </Tbody>
      </Table>
)}
    </Box>
    </Box>
  );
};

export default LearnQHome;
