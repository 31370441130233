import React, { useState, useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext.tsx';
import { getTeacherByEmail } from '../../Services/Teacher/teacherService.tsx';
import { getAuth } from 'firebase/auth';

interface ProtectedRouteProps {
  element: React.ReactElement;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ element }) => {
  const { user, loading } = useAuth();
  const location = useLocation();
  const [isAuthorized, setIsAuthorized] = useState<boolean | null>(null); 
  const [isLoading, setIsLoading] = useState(true);  // To track if the check is completed
  let status = true;

  useEffect(() => {
    const fetchTeacherData = async () => {
      if (loading) return; // Avoid running if the auth state is loading
      if (!user) {
        setIsAuthorized(false);  // No user, redirect to login
        setIsLoading(false);
        return;
      }

      try {
        // Fetch Google access token
        const fetchGoogleAccessToken = async () => {
          const auth = getAuth();
          const tokenResult = await user.getIdTokenResult(true);
          const token = tokenResult.token;
          sessionStorage.setItem("googleAccessToken", token);
          sessionStorage.setItem("TokenExpirationTime", tokenResult.expirationTime);
        };

        if (!sessionStorage.getItem("googleAccessToken")) {
          await fetchGoogleAccessToken();
        }

        // Fetch teacher data
        const teacher = await getTeacherByEmail(user.email);
        if (teacher) {
          sessionStorage.setItem("CurrentLoggedInTId", teacher._id);
          sessionStorage.setItem("userType", "teacher");
          sessionStorage.setItem("teacherUserData", JSON.stringify(teacher));

          // Check if teacher status is active
          if (teacher.status === 'active') {
            setIsAuthorized(true);
          } else if(teacher.status === 'inactive'){
            setIsAuthorized(false); // Teacher is not active, redirect to access denied
            status = false;
          }
          else{
            status = true;
            setIsAuthorized(false);
          }
        } else {
          setIsAuthorized(false); // Teacher not found
        }
      } catch (error) {
        console.error("Error fetching teacher data:", error);
        setIsAuthorized(false);
      } finally {
        setIsLoading(false); // Set loading to false after the check
      }
    };

    fetchTeacherData();
  }, [user, loading]);

  if (isLoading) {
    return <div>Loading...</div>; // Display loading state while checking
  }
  localStorage.setItem("redirectPath",location.pathname);

  return isAuthorized === null ? null : isAuthorized ? element : status ? <Navigate to={`/teacher-portal/login`} /> : <Navigate to={`/accessdenied`} />;

};

export default ProtectedRoute;
