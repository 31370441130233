// src/components/protectedRoute/ProtectedRoute.tsx
import React, { useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext.tsx';
import { getAdminByEmail } from '../../Services/Admin/AdminService.tsx';
import { getAuth } from 'firebase/auth';

interface ProtectedRouteProps {
  element: React.ReactElement;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ element }) => {
  const { user, loading } = useAuth();
  const location = useLocation();
  const [isAuthorized, setIsAuthorized] = useState<boolean | null>(null); 
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchAdminData = async () => {
      if (loading) return; 
      if (!user) {
        setIsAuthorized(false); 
        setIsLoading(false);
        return;
      }

      try {
        
        const fetchGoogleAccessToken = async () => {
          const auth = getAuth();
          const tokenResult = await user.getIdTokenResult(true);
          const token = tokenResult.token;
          sessionStorage.setItem("adminGoogleAccessToken", token);
          sessionStorage.setItem("AdminTokenExpirationTime", tokenResult.expirationTime);
        };

        if (!sessionStorage.getItem("adminGoogleAccessToken")) {
          await fetchGoogleAccessToken();
        }

       
        const admin = await getAdminByEmail(user.email);
        if (admin) {
          sessionStorage.setItem("CurrentLoggedInAId", admin._id);
          sessionStorage.setItem("userType", "admin");
          sessionStorage.setItem("AdminUserData", JSON.stringify(admin));
          setIsAuthorized(true);
        } else {
          setIsAuthorized(false); 
        }
      } catch (error) {
        console.error("Error fetching admin data:", error);
        setIsAuthorized(false);
      } finally {
        setIsLoading(false); 
      }
    };

    fetchAdminData();
  }, [user, loading]);

  if (isLoading) {
    return <div>Loading...</div>; 
  }
  localStorage.setItem("redirectPath",location.pathname);

  return isAuthorized === null ? null : isAuthorized ? element : <Navigate to={`/admin-portal/login`}/>;

};

export default ProtectedRoute;
