import React from 'react';
import { Layout } from './Layout.tsx';
import { ProcessingCard } from './ProcessingCard.tsx';

function Processing() {
  return (
    <Layout>
      <ProcessingCard />
    </Layout>
  );
}

export default Processing;