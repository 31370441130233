import axios from "axios";
import { PUBLIC_URL } from "../../commons/utils.tsx";

export const getTeachers = async (isAdmin) => {
    try {
      const response = await axios.post(`${PUBLIC_URL}/api/admin/getTeachers`, {
        isAdmin, 
      });
      return response.data; 
    } catch (error) {
      console.error('Error fetching teacher names:', error);
      throw new Error('Failed to fetch teacher names');
    }
  };

export const getStudents = async (isAdmin) => {
    try {
      const response = await axios.post(`${PUBLIC_URL}/api/admin/getStudents`, {
        isAdmin, 
      });
      return response.data; 
    } catch (error) {
      console.error('Error fetching student names:', error);
      throw new Error('Failed to fetch student names');
    }
  };

  export const getClasses = async (isAdmin) => {
    try {
      const response = await axios.post(`${PUBLIC_URL}/api/admin/getClasses`, {
        isAdmin, 
      });
      return response.data; 
    } catch (error) {
      console.error('Error fetching classes:', error);
      throw new Error('Failed to fetch classes:');
    }
  };

  export const getAdminByEmail = async (email) => {
    try {
      const response = await axios.get(`${PUBLIC_URL}/api/admin/${email}`);
      return response.data;
    } catch (error) {
      if (error.response) {
        if (error.response.status === 404) {
          throw new Error('Admin not found');
        }
      }
      console.error('Error fetching Admin:', error.message || error);
      throw new Error('Error fetching Admin');
    }
  };

  export const getAdminById = async (id) => {
    try {
      const response = await axios.post(`${PUBLIC_URL}/api/admin`, { id });
      return response.data;
    } catch (error) {
      if (error.response) {
        if (error.response.status === 404) {
          throw new Error('Admin not found');
        }
      }
      console.error('Error fetching Admin:', error.message || error);
      throw new Error('Error fetching Admin');
    }
  };

  export const changeTeacherStatus = async ({ adminId, teacherId, action }) => {
    try {
      const response = await axios.post(`${PUBLIC_URL}/api/admin/changeStatus`, {
        adminId,
        teacherId,
        action,
      });
      return response.data;
    } catch (error) {
      console.error('Error changing teacher status:', error);
      throw new Error('Failed to update teacher status');
    }
  };
  