import { Box } from '@chakra-ui/react';
import React from 'react';
import { ReactNode } from 'react';

interface LayoutProps {
  children: ReactNode;
}

export function Layout({ children }: LayoutProps) {
  return (
    <Box 
      minH="100vh"
      bg="gray.50"
      position="relative"
      overflow="hidden"
      display="flex"
      alignItems="center"
      justifyContent="center"
      p={4}
      _before={{
        content: '""',
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        bgGradient: "linear(to-br, blue.100, purple.100)",
        transform: "skewY(-6deg)",
        transformOrigin: "top left",
      }}
    >
      {children}
    </Box>
  );
}